@import "colors";
@import "typography";
@import "vendor/BarBars/barbars";

#sidebar {
    @include barbar--left(
        $width: 256px,
        $height: calc(100vh - 64px)
    );

    z-index: 5;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    -ms-transition: -ms-transform 0.5s ease-in-out;
    background-color: #fff;
    overflow: hidden;
    padding:$base-line-height/2 $base-line-height $base-line-height/2 $base-line-height;
    margin-top: 64px;
    border-right: 1px LightGray solid;

    &.pushed{
        @extend .barbar-reset;
    }
    
}

#main {
    background-color:#fff;
    height:100%;
    z-index:1;
    position:relative;
    padding-top: 64px;
}
