// Tables (https://raw.githubusercontent.com/twbs/bootstrap-sass/master/assets/stylesheets/bootstrap/_tables.scss)
caption {
  padding-top: 0;
  padding-bottom: 0;
  color: $grey;
  text-align: left;
}
th {
  text-align: left;
}

// Baseline styles
.table {
    width: 100%;
    max-width: 100%;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $base-line-height/4;
        line-height: $base-line-height/2;
        vertical-align: middle;
        border-top: 1px solid $grey;
        &:first-child {padding-left: 0;}
        &:last-child {padding-right: 0;}
      }
    }
  }
  
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
  }
  
  // No line-height if using p in table
  > tbody > tr > td > p {
    line-height: inherit;
  }
  
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
        line-height: $base-line-height/2;
        &:first-child {padding-left: 0;}
        &:last-child {padding-right: 0;}
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $grey;
  }

  // Nesting
  .table {
    background-color: #fff;
  }
}

.fixed-table {
    table-layout: fixed;

    @media (max-width: 767px) {
        width: 100%;
        overflow-x: scroll;
        display: block;
        table-layout: auto;
    }
}

.invalidpercent input, .invalidpercent .text {border-color: $red; color: $red;}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.
/*
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: ($line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $grey;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}
*/
