@media print {
    html {font-size: 40% !important}
    html, body {height: 99%;}
    .no-print, #header, #footer, #sidebar, .tabs, .phpdebugbar {
        display: none !important
    }
    
    .h2 {
        break-before: always;
        page-break-before: always;
        #input-container &, #employee-detail-container & {
            break-before: auto;
            page-break-before: auto;
        }
    }
    
    .print {
        display: block !important;
    }
    
    .print-flex {
        display: block !important;
        #budget_budget & {
            display: -webkit-flex !important;
            display: -ms-flexbox !important;
            display: flex !important;
        }
    }
    
    .print-break-after {
        display: block;
        page-break-after: always;
    }
    
    .print-break-before {
        display: block;
        page-break-before: always;
    }
    
    .no-print-break {
        display: block;
        page-break-before: auto;
        page-break-after: auto;
        break-before: auto;
        break-after: auto;
    }
    
    table {
        -webkit-region-break-inside: avoid;
        page-break-inside: avoid;
        display: table !important;
        &.fixed-table {
            width: 100%;
            overflow-x: none;
            display: table !important;
            table-layout: auto;
            
            #budget_budget &, #input-container &, #department-container & {
                table-layout: fixed;
            }
        }
        
        tr {
            page-break-inside: avoid;
        }
        td {
            page-break-inside:avoid;
        }
    }

    .lighten {opacity:1.0 !important}
    
    header.only-print {
        position: fixed;
        top: 0;
    }
    #main {padding-top: 0 !important}
    footer.only-print {
        position: fixed;
        bottom: 0;
    }
    
    @page {
	/* size: A4 ;*/
    }
    @page :first {
    }
    @page :left {
    }

    @page :right {
    }

    iframe {
        display: none;
    }

    .icon-inline {
        display: none;
    }

    * {
        -ms-overflow-style: none;
    }

    textarea {
        border-style: none;
        width: 100%;
    }
    
    .tooltip-content { 
        display:none;
        position:relative;
    }

    .depchart {
        max-width: 100%;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
    }

    .bigchart {
        max-width: 100%;
    }

    #budget_budget {
        .icon-inline {
            display:inline;
        }
        .table thead tr th,
        .table thead tr td,
        .table tbody tr th,
        .table tbody tr td {
            padding: 0.8rem 0.4rem;
            
            & .form-input {
                border-radius: 0px;
                border-width: 0px;
                padding: 0px;
            }
        }
    }
    
    #department-container {
        #table-avskrivning tr {
            th, td { width:11.1%;
                &:first-child { width:22.2%; }
                &:nth-child(2) { text-align:right; }
            }
        }
        #table-lokalkostnad tr {
            th, td { width:11.1%;
                &:first-child { width:33.3%; }
            }
        }
    }
    
    #company-container, #input-container, #department-container, #report-container, #employee-detail-container {
        .row {
            display: block;
            margin-left: 0px;
            margin-right: 0px;
        }
        .col-xs,
        .col-xs-1,
        .col-xs-2,
        .col-xs-3,
        .col-xs-4,
        .col-xs-5,
        .col-xs-6,
        .col-xs-7,
        .col-xs-8,
        .col-xs-9,
        .col-xs-10,
        .col-xs-11,
        .col-xs-12,
        .col-xs-offset-1,
        .col-xs-offset-2,
        .col-xs-offset-3,
        .col-xs-offset-4,
        .col-xs-offset-5,
        .col-xs-offset-6,
        .col-xs-offset-7,
        .col-xs-offset-8,
        .col-xs-offset-9,
        .col-xs-offset-10,
        .col-xs-offset-11,
        .col-xs-offset-12,
        .col-sm,
        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm-offset-1,
        .col-sm-offset-2,
        .col-sm-offset-3,
        .col-sm-offset-4,
        .col-sm-offset-5,
        .col-sm-offset-6,
        .col-sm-offset-7,
        .col-sm-offset-8,
        .col-sm-offset-9,
        .col-sm-offset-10,
        .col-sm-offset-11,
        .col-sm-offset-12,
        .col-md,
        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-10,
        .col-md-11,
        .col-md-12,
        .col-md-offset-1,
        .col-md-offset-2,
        .col-md-offset-3,
        .col-md-offset-4,
        .col-md-offset-5,
        .col-md-offset-6,
        .col-md-offset-7,
        .col-md-offset-8,
        .col-md-offset-9,
        .col-md-offset-10,
        .col-md-offset-11,
        .col-md-offset-12,
        .col-lg,
        .col-lg-1,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12,
        .col-lg-offset-1,
        .col-lg-offset-2,
        .col-lg-offset-3,
        .col-lg-offset-4,
        .col-lg-offset-5,
        .col-lg-offset-6,
        .col-lg-offset-7,
        .col-lg-offset-8,
        .col-lg-offset-9,
        .col-lg-offset-10,
        .col-lg-offset-11,
        .col-lg-offset-12 {
            display: block;
            padding-left: 0px;
            padding-right: 0px;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
        }
    }

}
