.icon {
    &-inline {vertical-align: middle; margin-top: -2px; opacity:0.25490196078431; opacity: 1;}
    &-rotate45 {transform: rotate(45deg); -webkit-transform:rotate(45deg);}
    &-rotate90 {transform: rotate(90deg); -webkit-transform:rotate(90deg);}
    &-rotate180 {transform: rotate(180deg); -webkit-transform:rotate(180deg);}
}
.manufacturer-icon {
	&-mercedes-benz {background:transparent url('../images/manufacturer/Mercedes-Benz/Mercedes-Benz.svg') 20px no-repeat; background-size: 32px;}
	&-audi {background:transparent          url('../images/manufacturer/Audi/Audi.svg') 20px no-repeat; background-size: 32px;}
	&-bmw {background:transparent           url('../images/manufacturer/BMW/BMW.svg') 20px no-repeat; background-size: 32px;}
	&-porsche {background:transparent       url('../images/manufacturer/Porsche/Porsche.svg') 20px no-repeat; background-size: 32px;}
	&-vw {background:transparent            url('../images/manufacturer/Volkswagen/Volkswagen.svg') 20px no-repeat; background-size: 32px;}
	&-opel {background:transparent          url('../images/manufacturer/Opel/Opel.svg') 20px no-repeat; background-size: 32px;}
	&-honda {background:transparent         url('../images/manufacturer/Honda/Honda.svg') 20px no-repeat; background-size: 32px;}
	&-volvo {background:transparent         url('../images/manufacturer/Volvo/Volvo.svg') 20px no-repeat; background-size: 32px;}
    &-skoda {background:transparent         url('../images/manufacturer/Skoda/Skoda.svg') 20px no-repeat; background-size: 32px;}
    &-alfaromeo {background:transparent         url('../images/manufacturer/Alfa_Romeo/Alfa_Romeo.svg') 20px no-repeat; background-size: 32px;}
    &-chevrolet {background:transparent         url('../images/manufacturer/Chevrolet/Chevrolet.svg') 20px no-repeat; background-size: 32px;}
    &-citroen {background:transparent         url('../images/manufacturer/Citroen/Citroen.svg') 20px no-repeat; background-size: 32px;}
    &-dacia {background:transparent         url('../images/manufacturer/Dacia/Dacia.svg') 20px no-repeat; background-size: 32px;}
    &-fiat {background:transparent         url('../images/manufacturer/Fiat/Fiat.svg') 20px no-repeat; background-size: 32px;}
    &-ford {background:transparent         url('../images/manufacturer/Ford/Ford.svg') 20px no-repeat; background-size: 32px;}
    &-hyundai {background:transparent         url('../images/manufacturer/Hyundai/Hyundai.svg') 20px no-repeat; background-size: 32px;}
    &-jaguar {background:transparent         url('../images/manufacturer/Jaguar/Jaguar.svg') 20px no-repeat; background-size: 32px;}
    &-jeep {background:transparent         url('../images/manufacturer/Jeep/Jeep.svg') 20px no-repeat; background-size: 32px;}
    &-kia {background:transparent         url('../images/manufacturer/Kia/Kia.svg') 20px no-repeat; background-size: 32px;}
    &-lancia {background:transparent         url('../images/manufacturer/Lancia/Lancia.svg') 20px no-repeat; background-size: 32px;}
    &-landrover {background:transparent         url('../images/manufacturer/Land_Rover/Land_Rover.svg') 20px no-repeat; background-size: 32px;}
    &-lexus {background:transparent         url('../images/manufacturer/Lexus/Lexus.svg') 20px no-repeat; background-size: 32px;}
    &-mazda {background:transparent         url('../images/manufacturer/Mazda/Mazda.svg') 20px no-repeat; background-size: 32px;}
    &-mini {background:transparent         url('../images/manufacturer/Mini/Mini.svg') 20px no-repeat; background-size: 32px;}
    &-mitsubishi {background:transparent         url('../images/manufacturer/Mitsubishi/Mitsubishi.svg') 20px no-repeat; background-size: 32px;}
    &-nissan {background:transparent         url('../images/manufacturer/Nissan/Nissan.svg') 20px no-repeat; background-size: 32px;}
    &-peugeot {background:transparent         url('../images/manufacturer/Peugeot/Peugeot.svg') 20px no-repeat; background-size: 32px;}
    &-renault {background:transparent         url('../images/manufacturer/Renault/Renault.svg') 20px no-repeat; background-size: 32px;}
    &-saab {background:transparent         url('../images/manufacturer/Saab/Saab.svg') 20px no-repeat; background-size: 32px;}
    &-seat {background:transparent         url('../images/manufacturer/Seat/Seat.svg') 20px no-repeat; background-size: 32px;}
    &-smart {background:transparent         url('../images/manufacturer/Smart/Smart.svg') 20px no-repeat; background-size: 32px;}
    &-subaru {background:transparent         url('../images/manufacturer/Subaru/Subaru.svg') 20px no-repeat; background-size: 32px;}
    &-suzuki {background:transparent         url('../images/manufacturer/Suzuki/Suzuki.svg') 20px no-repeat; background-size: 32px;}
    &-toyota {background:transparent         url('../images/manufacturer/Toyota/Toyota.svg') 20px no-repeat; background-size: 32px;}
}
