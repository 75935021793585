$red: #f84545;
    $warning: $red;
$green: #85b91e;
    $ok: #3eb5ac;
$blue: #078dd8;
    $deep-blue: #009dcd;
$grey: #d4d4d5;
    $less-grey: #f2f2f2;


$notice: #f58410;
