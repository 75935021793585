@mixin translateY ($val) {
    transform: translateY($val);
    -webkit-transform: translateY($val);
    -ms-transform: translateY($val);
}

@mixin translateX ($val) {
    transform: translateX($val);
    -webkit-transform: translateX($val);
    -ms-transform: translateX($val);
}

@mixin barbar--mixin () {
    z-index: 1;
    position: fixed;
}

@mixin barbar--top($width:100, $unit:false, $height:false) {
    @include barbar--mixin();
    @include translateY(-100%);
    width: $width;

    @if $unit == false {
        @media screen and (orientation: landscape) {
            @if $height != false {
                height: #{$height}vmin;
            }
        }

        @media screen and (orientation: portrait) {
            @if $height != false {
                height: #{$height}vmax;
            }
        }
    }
    @else {
        @if $height != false {
            height: #{$height}#{$unit};
        }
    }


}

@mixin barbar--bottom($width:100vw, $unit:false, $height:false) {
    @include barbar--mixin();
    @include translateY(200%);
    width: $width;
    bottom: 0px;

    @if $unit == false {
        @media screen and (orientation: landscape) {
            @if $height != false {
                height: #{$height}vmin;
            }
        }

        @media screen and (orientation: portrait) {
            @if $height != false {
                height: #{$height}vmax;
            }
        }
    }
    @else {
        @if $height != false {
            height: #{$height}#{$unit};
        }
    }

}

@mixin barbar--left($width:10vw, $height:100vh) {
    @include barbar--mixin();
    @include translateX(-100%);
    height: $height;
    width: $width;
    top: 0px;
}

@mixin barbar--right($width:10, $unit:false, $height:100vh) {
    @include barbar--mixin();
    @include translateX(200%);
    height: $height;
    right: 0px;
    top: 0px;

    @if $unit != false {
        width: #{$width}#{$unit};
    }
    @else {
        width: #{$width}vw;
    }

}

@mixin barbar--main($top:0px, $right:0px, $bottom:0px, $left:0px) {
    position: absolute;
    z-index: 0;
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
}

.barbar-reset{
    @include translateY(0px);
    @include translateX(0px);
}
