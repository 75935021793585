// Typography
$base-font-family: 'Open Sans';
html {font-size: 62.5%; font-family: 'Open Sans';} //62.5% of 16px font size
$base-font-size: 1.2rem;
$base-line-height: 3.2rem;
$base-font-color: #000;
$base-font-opacity: 0.6;
$light-font-color: rgba($base-font-color, $base-font-opacity);
//@import url(http://fonts.googleapis.com/css?family=Lato:400,100,200,300,600,500,700,800,900);
@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
/* Regular */
@font-face {
    font-family: 'Open Sans';    
    src: url('../fonts/OpenSans-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-weight: 400;
    font-style: normal;
}

/* Light */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

/* Semibold */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/* Bold */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold-webfont.woff') format('woff');
    font-weight: bold;
    font-weight: 700;
    font-style: normal;
}

/* Extra Bold */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBold-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

// Defaults
h1, h2, h3, h4, h5, h6, p, li, a, button, label, input, select, table, .text, small {margin: 0; font-size: $base-font-size; font-weight: normal; line-height: $base-line-height;}
    .h1, .h2, .h3, .h4, .h5, .h6 {font-weight: 200;}
    .h1 {font-size: 3.6rem; padding: $base-line-height 0 $base-line-height 0;}
    .h2 {font-size: 2.6rem; padding-top: $base-line-height;}
    .h3 {font-size: 1.8rem; padding-top: $base-line-height; }
a {text-decoration: none; color: $base-font-color;}
ol, ul {margin: 0; padding: 0; list-style-type: none;}
.num {font-family: Menlo,Monaco,Consolas,"Courier New",monospace; font-size: inherit;}
.small {font-size: 1rem;}
.bold {font-weight: 500;}
.bolder {font-weight: 700;}
.shortcut {opacity: 0.4;}

// Classes
.font {
	&-weight {
		&--bold {font-weight: bold;}
		&--normal {font-weight: normal}
	}
	&-size {
		&--small {font-size: 1.2rem; vertical-align:baseline;}
        &--large {font-size: 2.06rem; vertical-align:top;}
	}
}
.line-height {
	&--small {line-height: 1.6rem}
	&--half {line-height: $base-line-height/2;}
	&--normal {line-height: $base-line-height;}
	&--double {line-height: $base-line-height*2;}
}
.align {
	&--right {text-align: right;}
	&--left {text-align: left;}
	&--center {text-align: center;}
}

// Link colors (from colors.scss)
.linkcolor {
    &--red {color: $red; fill: $red;}
    &--green {color: $green; fill: $green;}
    &--blue {color: $blue; fill: $blue;}
    &--grey {color: #666; fill: #999;}
}

.less-line-height {
    p {
        line-height: 16px;
    }
}
