@import "../../node_modules/bourbon/app/assets/stylesheets/_bourbon.scss";

.tooltip {
    display: block;
    position: relative;
    z-index: 999;

    .inline {
        display: inline;
    }
}

/* Trigger text */
.tooltip-item {}
/* Gap filler */

.tooltip-item::after {
    content: '';
    position: absolute;
    width: 100%;
    max-width: 360px;
    height: 20px;
    bottom: 100%;
    left: 50%;
    pointer-events: none;
    @include transform(translateX(-50%));
    // -webkit-transform: translateX(-50%);
    // transform: translateX(-50%);
}

.tooltip:hover .tooltip-item:after {
    pointer-events: auto;
}

/* Tooltip */
.tooltip-content {
    position: absolute;
    z-index: 9999;
    width: auto;
    text-align: left;
    word-break: break-word;
    width:100%;
    right: 0;
    left: 0;
    margin-bottom: $base-line-height/4;
    padding: $base-line-height/2;
    bottom: 100%;
    font-size: $base-font-size;
    color: #fff;
    line-height: 1.4;
    box-shadow: -5px -5px 15px rgba(48,54,61,0.2);
    background: #078dd8;
    opacity: 0;
    cursor: default;
    pointer-events: none;
}

.tooltip-effect-2 .tooltip-content {
    @include transform-origin(50% calc(100% + 10px));
    // -webkit-transform-origin: 50% calc(100% + 10px);
    // transform-origin: 50% calc(100% + 10px);
    @include transform(
        perspective(1000px)
        rotate3d(1,0,0,45deg)
    );
    // -webkit-transform: perspective(1000px) rotate3d(1,0,0,45deg);
    // transform: perspective(1000px) rotate3d(1,0,0,45deg);
    @include transition(
        perspective(1000px)
        rotate3d(1,0,0,45deg)
    );
    // -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s;
}

.tooltip:hover .tooltip-content {
    pointer-events: auto;
    opacity: 1;
    @include transform(
        translate3d(0,0,0)
        rotate3d(0,0,0,0)
        translateY(100%)
    );
    // -webkit-transform: translate3d(0,0,0) rotate3d(0,0,0,0);
    // transform: translate3d(0,0,0) rotate3d(0,0,0,0);
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
    @include transform(perspective(1000px) rotate3d(1,0,0,0deg));
    // -webkit-transform: perspective(1000px) rotate3d(1,0,0,0deg);
    // transform: perspective(1000px) rotate3d(1,0,0,0deg);
}
