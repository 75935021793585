// Gear (Based on http://pasqualevitiello.github.io/Tumblr-Style-Cog-Spinners/)
//Usage: Add .animation-active to parent container, ie. row
.animation {
    &-loader {position: relative;
        &::after {content: ""; position: absolute; right: 0; top: 50%; margin-top: -$base-line-height/2; animation: spinning-gear 3s infinite ease; display: block; width: $base-line-height; height: $base-line-height; background:transparent url('../images/loading-gear.svg') left top no-repeat; background-size: 100%;}
        & * {opacity: 0;}
    }
    @keyframes spinning-gear {
      0% {transform: rotate(0deg)}
      20% {transform: rotate(-45deg)}
      100% {transform: rotate(360deg)}
    }
    @keyframes shrinking-gear {
      0% {transform: scale(1)}
      20% {transform: scale(1.2)}
      100% {transform: scale(0)}
    }
    @keyframes growing-gear {
      0% {transform: scale(0)}
      20% {transform: scale(1.2)}
      100% {transform: scale(1)}
    }
    &-loader--out::after {
        animation: shrinking-gear .3s 1 ease forwards;
    }
}

@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
    50% {
        opacity: 1;
    }
}
@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
    50% {
        opacity: 1;
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation: zoomIn 0.5s;
    animation: zoomIn 0.5s;
}

.jsx_loader{
    transition: opacity 1s ease-in-out;
}

/* Jelena */
// http://tympanus.net/codrops/2015/02/11/subtle-click-feedback-effects/
.btnX::after {
	border: 2px solid rgba(111,148,182,0.5);
    border: 2px solid #000;
}

.btnX--click::after {
	-webkit-animation: anim-effect-jelena 0.3s ease-out forwards;
	animation: anim-effect-jelena 0.3s ease-out forwards;
}

@-webkit-keyframes anim-effect-jelena {
	0% {
		opacity: 1;
		-webkit-transform: scale3d(0.5, 0.5, 1);
		transform: scale3d(0.5, 0.5, 1);
	}
	to {
		opacity: 0;
		-webkit-transform: scale3d(1.2, 1.2, 1);
		transform: scale3d(1.2, 1.2, 1);
	}
}

@keyframes anim-effect-jelena {
	0% {
		opacity: 1;
		-webkit-transform: scale3d(0.5, 0.5, 1);
		transform: scale3d(0.5, 0.5, 1);
	}
	to {
		opacity: 0;
		-webkit-transform: scale3d(1.2, 1.2, 1);
		transform: scale3d(1.2, 1.2, 1);
	}
}
